import { Box, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import type { FC } from 'react';
import type { UserObject } from '../../entities';
import { Close } from '../../graphics';
import MemberRegistrationPrompt from './MemberRegistrationPrompt';
import { generateDialogTitle } from 'src/helpers/userRegistrationHelpers';

export interface Props {
  onClose?: () => void;
  open: boolean;
  user: UserObject | null;
  isSimpleForm?: boolean;
  showClose?: boolean;
  title?: string;
}

const SignupDialog: FC<Props> = ({ onClose = () => {}, open, user, isSimpleForm = false, showClose, title }) => {
  const dialogTitle = title ?? generateDialogTitle(user);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#fff',
        },
      }}
    >
      {showClose !== true && <DialogTitle>{dialogTitle}</DialogTitle>}
      {showClose === true && (
        <DialogTitle>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>{dialogTitle}</Box>
            <Box>
              <IconButton size="small" onClick={onClose} sx={{ ml: 1 }}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
      )}
      <DialogContent sx={{ mx: 0, px: 1 }}>
        <MemberRegistrationPrompt user={user} isSimpleForm={isSimpleForm} />
      </DialogContent>
    </Dialog>
  );
};

export default SignupDialog;
